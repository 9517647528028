// 验证码倒计时
import { ref } from 'vue'

export default function selectButton () {
  const text = ref('获取验证码')
  const disabled = ref(false)
  function countTime () {
    var timeo = 60
    var timeStop = setInterval(() => {
      timeo--
      if (timeo > 0) {
        text.value = timeo + 's'
        disabled.value = true
      } else {
        timeo = 60
        text.value = '获取验证码'
        disabled.value = false
        clearInterval(timeStop)
      }
    }, 1000)
  }

  return { text, disabled, countTime }
}
